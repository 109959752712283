import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { ReferralMetadataService } from '../services/referral/referral-metadata.service';

export const referralResolver: ResolveFn<any> = route => {
  const orderId = route.queryParamMap.get('source') || '';
  const shareWithImage = route.queryParamMap.get('shareWithImage') === 'true';

  return inject(ReferralMetadataService).getDeliveredOrderImage(orderId, shareWithImage);
};
