import { Routes } from '@angular/router';

import { DownloadAppComponent } from './pages/download-app/download-app.component';
import { GiftCardsComponent } from './pages/gift-cards/gift-cards/gift-cards.component';
import { PressComponent } from './pages/press/press/press.component';
import { referralResolver } from './resolvers/referral.resolver';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () => import('./pages/home/home.component').then(m => m.HomePage),
    resolve: {
      data: referralResolver,
    },
  },
  {
    path: 'united',
    loadComponent: () => import('./pages/united-health/unitedhealth.component').then(m => m.UnitedHealthPage),
  },
  {
    path: 'about',
    loadComponent: () => import('./pages/about/about.component').then(m => m.AboutPage),
    data: {
      canonical: 'about',
    },
  },
  {
    path: 'contact',
    loadComponent: () => import('./pages/contact/contact.component').then(m => m.ContactComponent),
    data: {
      canonical: 'contact',
    },
  },
  {
    path: 'lp-video-testimonials',
    loadComponent: () =>
      import('./pages/boast/lp-video-testimonials/lp-video-testimonials.component').then(
        m => m.LpVideoTestimonialsComponent
      ),
  },
  {
    path: 'customer-video-testimonials',
    loadComponent: () =>
      import('./pages/boast/customer-video-testimonials/customer-video-testimonials.component').then(
        m => m.CustomerVideoTestimonialsComponent
      ),
  },
  {
    path: 'gift-cards',
    component: GiftCardsComponent,
    data: {
      canonical: 'gift-cards',
    },
  },
  {
    path: 'work-from-home',
    loadComponent: () =>
      import('./pages/professional-launderers/professional-launderers.component').then(
        m => m.ProfessionalLaunderersComponent
      ),
    data: {
      canonical: 'work-from-home',
    },
  },
  {
    path: 'blog',
    loadComponent: () => import('./pages/blog/blog.component').then(m => m.BlogComponent),
    data: {
      canonical: 'blog',
    },
  },
  {
    path: 'blog/categories',
    loadComponent: () => import('./pages/blog/categories/categories.component').then(m => m.CategoriesComponent),
    data: {
      canonical: 'blog/categories',
    },
  },
  {
    path: 'blog',
    data: {
      canonical: 'blog',
    },
    children: [
      {
        path: ':slug',
        loadComponent: () => import('./pages/blog/post/post.component').then(m => m.PostComponent),
      },
    ],
  },
  {
    path: 'careers',
    loadComponent: () => import('./pages/careers/careers.component').then(m => m.CareersComponent),
    data: {
      canonical: 'careers',
    },
  },
  {
    path: 'business',
    loadComponent: () => import('./pages/business/business.component').then(m => m.BusinessComponent),
    data: {
      canonical: 'business',
    },
  },
  {
    path: 'affiliates',
    loadComponent: () => import('./pages/affiliates/affiliates.component').then(m => m.AffiliatesComponent),
    data: {
      canonical: 'affiliates',
    },
  },
  {
    path: 'press-releases',
    component: PressComponent,
    data: {
      canonical: 'press-releases',
    },
  },
  {
    path: 'privacy-policy',
    loadComponent: () => import('./pages/privacy-policy/privacy-policy.component').then(m => m.PrivacyPolicyComponent),
    data: {
      canonical: 'privacy-policy',
    },
  },
  {
    path: 'terms-of-service',
    loadComponent: () =>
      import('./pages/terms-of-service/terms-of-service.component').then(m => m.TermsOfServiceComponent),
    data: {
      canonical: 'terms-of-service',
    },
  },
  {
    path: 'ada-notice',
    loadComponent: () => import('./pages/ada-notice/ada-notice.component').then(m => m.ADANoticeComponent),
    data: {
      canonical: 'ada-notice',
    },
  },
  {
    path: 'download-poplin-pro-app',
    component: DownloadAppComponent,
  },
  {
    path: 'site-link-1',
    loadComponent: () => import('./pages/home/home.component').then(m => m.HomePage),
  },
  {
    path: 'site-link-2',
    loadComponent: () => import('./pages/home/home.component').then(m => m.HomePage),
  },
  {
    path: 'site-link-3',
    loadComponent: () => import('./pages/home/home.component').then(m => m.HomePage),
  },
  {
    path: 'site-link-4',
    loadComponent: () => import('./pages/home/home.component').then(m => m.HomePage),
  },
  {
    path: 'same-day-laundry',
    loadComponent: () => import('./pages/home/home.component').then(m => m.HomePage),
  },
  {
    path: 'do-my-laundry',
    loadComponent: () => import('./pages/home/home.component').then(m => m.HomePage),
  },
  {
    path: 'apply',
    loadComponent: () => import('./pages/home/home.component').then(m => m.HomePage),
  },
  {
    path: 'gift',
    loadComponent: () => import('./pages/home/home.component').then(m => m.HomePage),
  },
  {
    path: 'freetrial',
    children: [
      {
        path: '**',
        loadComponent: () => import('./pages/home/home.component').then(m => m.HomePage),
      },
    ],
  },
  //  404 redirect to home, must be last
  { path: '**', redirectTo: '', pathMatch: 'full' },
];
