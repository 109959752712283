import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ApiService } from '../api/api.service';

/**
 * A service to read referral info from query params and dynamically set
 * Open Graph (Facebook) metadata (and others).
 */
@Injectable({
  providedIn: 'root',
})
export class ReferralMetadataService {
  DELIVERY_IMAGE_URL = `/customer/v1/order/delivery-image`;

  fallbackDeliveredImage = environment.sharing.fallbackDeliveredImageURL;

  constructor(
    private route: ActivatedRoute,
    private meta: Meta,
    private titleService: Title,
    private apiService: ApiService
  ) {}

  public async setReferralMetadata(imageUrl?: string): Promise<void> {
    const referralId = this.route.snapshot.queryParamMap.get('referralId') || '';

    if (!referralId) {
      return;
    }

    const title = 'Poplin has honestly changed my life.';
    const description =
      'The Poplin laundry service has honestly changed my life and I think you’d love it. They’ll pick up your laundry from your house and bring it back the next day—with free delivery! It’ll be washed, dried, folded, sorted—ready to be put away. Use this link to get $10 off your first order. Enjoy!';

    if (!imageUrl) {
      imageUrl = this.fallbackDeliveredImage;
    }

    this.titleService.setTitle(title);

    this.meta.updateTag({ property: 'og:title', content: title });
    this.meta.updateTag({ property: 'og:description', content: description });
    this.meta.updateTag({ property: 'og:image', content: imageUrl });
    this.meta.updateTag({ property: 'og:url', content: `https://wwww.poplin.co?referralId=${referralId}` });
  }

  async getDeliveredOrderImage(orderId: string, shareWithImage: boolean): Promise<string> {
    if (orderId && shareWithImage) {
      try {
        const orderImage = await firstValueFrom(this.apiService.get(`${this.DELIVERY_IMAGE_URL}/${orderId}`));
        const deliveredImage = typeof orderImage?.data === 'object' ? orderImage?.data?.['deliveryImage'] : '';

        return deliveredImage && deliveredImage !== '' ? deliveredImage : this.fallbackDeliveredImage;
      } catch (e: any) {
        console.error('Error fetching delivered image, returning the fallback', e);
      }
    }

    return this.fallbackDeliveredImage;
  }
}
